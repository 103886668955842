<div class="interacts-present" *ngIf="showInteractsPresent">
  <ng-lottie
    [options]="{path: '/assets/animations/touch.json'}"
  ></ng-lottie>
</div>
<div class="interacts-present-bottom"
     *ngIf="!hideTabBar"
>
  <span class="jump">Touch the screen to interact</span>
  <div class="interacts-present-bottom-line"></div>
</div>
<div
  class="tab-bar tab-bar__with-bg"
  [class.hide]="!hideTabBar"
>
  <div class="tabs" #tabs appActivitySection="TabBar">
    <button
      class="tab"
      [routerLink]="isMenuPage ? '/main/home' : '/main/the-keg/menu'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isMenuPage" src="/themes/the-keg/assets/images/menu.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isMenuPage">
        <span>{{ 'tabBar.menu' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isInvoicePage ? '/main/home' : '/main/the-keg/invoice'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
    >
      <div class="icon">
        <img [class.active]="isInvoicePage" src="/themes/the-keg/assets/images/invoice.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isInvoicePage">
        <span>{{ 'tabBar.invoice' | translate }}</span>
      </div>
    </button>

    <button class="tab logo"
            routerLink='/main/home'
            appUserActivityClickTracking="PageClose"
      >
        <img class="logoImg" src="/themes/the-keg/assets/images/logo.png" alt=""/>
    </button>

    <button
      class="tab"
      [routerLink]="isCharityPage ? '/main/home' : '/main/the-keg/charity'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isCharityPage ? 'CharityPageClose' : 'CharityPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isCharityPage" src="/themes/the-keg/assets/images/charity.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isCharityPage">
        <span>{{ 'tabBar.charity' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isStorePage ? '/main/home' : '/main/the-keg/store'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isStorePage ? 'StorePageClose' : 'StorePageOpen'"
    >
      <div class="icon">
        <img [class.active]="isStorePage" src="/themes/the-keg/assets/images/store.svg" alt=""  />
      </div>
      <div class="label" [class.active]="isStorePage">
        <span>{{ 'tabBar.store' | translate }}</span>
      </div>
    </button>
  </div>
</div>
